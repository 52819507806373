
  import { Component, Prop } from 'vue-property-decorator'

  import Row from './row.vue'
  import { LinkProps } from './interfaces'

@Component({
  components: {
    Row,
  },
})
  export default class Link extends Row implements LinkProps {
  @Prop({ type: String, default: null }) link!: string
  @Prop({ type: String, default: '_blank' }) target?: string
  @Prop({ type: String, default: 'small' }) size?: string
  @Prop({ type: Boolean, default: true }) fab?: boolean
  @Prop({ type: Boolean, default: true }) dark?: boolean
  @Prop({ type: Number, default: 0 }) elevation?: boolean

  get btnLink () {
    const { link } = this
    // TODO: Verify valid link.
    if (!link) return undefined

    return link
  }

  get btn () {
    const { btnLink: href, target, size, color, fab, dark, elevation } = this
    const disabled = !href

    return {
      disabled,
      href,
      target,
      [size]: true,
      color,
      fab,
      dark,
      elevation,
    }
  }

  get extendBind () {
    const { container = true } = this

    return { container }
  }
  }
